input {
  width: 100%;
  padding: 8px 12px;
  box-shadow: 0 0 0 1px #e0e0e0, 0 2px 4px 0 rgb(0 0 0 / 7%),
    0 1px 1.5px 0 rgb(0 0 0 / 5%);
  transition: box-shadow 0.08s ease-in, color 0.08s ease-in, filter 50000s;
  outline: none;
  border: none;
  font-size: 16px;
  font-family: inherit;
  border-radius: 6px;
}

.input {
  font-size: 16px;
}

.input input:focus {
  box-shadow: 0 0 0 1px rgb(50 151 211 / 30%), 0 1px 1px 0 rgb(0 0 0 / 7%),
    0 0 0 4px rgb(50 151 211 / 30%);
}

.input-container.error .input input {
  box-shadow: 0 0 0 1px rgba(255, 72, 0, 0.3), 0 1px 1px 0 rgba(151, 0, 0, 0.07),
    0 0 0 4px rgba(253, 30, 0, 0.3);
}
