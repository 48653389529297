* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  --bg-gray: rgb(255, 255, 255);
  --light-gray: rgba(26, 26, 26, 0.5);
  --gray: rgba(26, 26, 26, 1);
  --red: rgb(255, 0, 0);
  --orange: #ea6b3d;
  /* --dark-blue: #292d39; */
  --dark-blue: rgb(25, 37, 82);
  --very-light-blue: rgb(246, 248, 251);
  --green: rgb(115, 213, 154);
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  color: var(--dark-blue);
  line-height: 1.3;
  -webkit-text-size-adjust: 100%;
}
